import img1 from "../assets/icons/phone.svg";
import img2 from "../assets/icons/mail.svg";
import img3 from "../assets/icons/globe.svg";
import img4 from "../assets/icons/clock.svg";
import img5 from "../assets/icons/whatsapp.svg";

const ContactCardData = [
  {
    image: img1,
    title: "Phone",
    text: "+91-8939298861",
    link: "tel:+91-8939298861",
    secondary: {
      image: img5,
      link: "https://wa.me/message/LTZVNXQTBA2NO1",
    },
  },
  {
    image: img2,
    title: "Mail",
    text: "contact@hustlerslogic.com",
    link: "mailto:contact@hustlerslogic.com",
  },
  {
    image: img3,
    title: "Address",
    text: "Chennai, INDIA",
  },
  {
    image: img4,
    title: "Working Hours",
    text: "9AM - 7PM (All Days)",
  },
];

export default ContactCardData;
