import { NavLink } from "react-router-dom";
import "../styles/About.css";

import React from "react";

const SocialCard = (props) => {
  return (
    <div className="social-card">
      <NavLink to={props.link} className="social-card-button" target="_blank">
        <div className="social-card-img-container">
          <img className="image" src={props.image} alt="social-card-img" />
        </div>
      </NavLink>
    </div>
  );
};

export default SocialCard;
