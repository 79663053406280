import "../styles/Project.css";

import React from "react";
import { NavLink } from "react-router-dom";

const ProjectCard = (props) => {
  return (
    <div className="project-card">
      <img src={props.image} alt="project-card-img" />
      <h2 className="project-card-title">{props.title}</h2>
      <h3 className="project-card-text">{props.text}</h3>
      <div>
        <NavLink
          to={props.link}
          className="project-card-button"
          target="_blank"
        >
          {props.button}
        </NavLink>
      </div>
    </div>
  );
};

export default ProjectCard;
