import "../styles/NavBar.css";

import Logo from "../assets/icons/navlogo.svg";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";

const NavBar = () => {
  const [hamburgerVisible, setHamburgerVisibility] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const toggleHamburger = () => setHamburgerVisibility(!hamburgerVisible);

  const updateNavBar = () => {
    if (window.scrollY >= 100) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const scrollToView = (sectionId) => {
    if (hamburgerVisible) toggleHamburger();
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  window.addEventListener("scroll", updateNavBar);

  return (
    <div className={scrolled ? "header header-bg" : "header"}>
      <Link to="/">
        <h1>
          <img className="image" src={Logo} alt="logo-img" />
        </h1>
      </Link>
      <ul className={hamburgerVisible ? "nav-menu active" : "nav-menu"}>
        <li>
          <Link onClick={() => scrollToView("home")}>Home</Link>
        </li>
        <li>
          <Link onClick={() => scrollToView("projects")}>Projects</Link>
        </li>
        <li>
          <Link onClick={() => scrollToView("about")}>About</Link>
        </li>
        <li>
          <Link onClick={() => scrollToView("contact")}>Contact</Link>
        </li>
      </ul>
      <div className="hamburger" onClick={toggleHamburger}>
        {hamburgerVisible ? (
          <FaTimes size={20} style={{ color: "#fff" }} />
        ) : (
          <FaBars size={20} style={{ color: "#fff" }} />
        )}
      </div>
    </div>
  );
};

export default NavBar;
