import "../styles/App.css";

import React from "react";

const GradientLine = (props) => {
  return (
    <div class="gradient-container">
      <div class="gradient-line"></div>
    </div>
  );
};

export default GradientLine;
