import "../styles/Contact.css";

import React from "react";
import { NavLink } from "react-router-dom";

const ContactCard = (props) => {
  return (
    <div className="contact-card">
      <div className="contact-card-img-container">
        <img className="image" src={props.image} alt="contact-card-img" />
      </div>
      <div className="contact-card-desc-container">
        <h2 className="contact-card-desc-title">{props.title}</h2>

        {props.link ? (
          <NavLink to={props.link} target="_blank">
            <h3 className="contact-card-desc-text">{props.text}</h3>
          </NavLink>
        ) : (
          <h3 className="contact-card-desc-text">{props.text}</h3>
        )}
      </div>
      {props.secondary ? (
        <NavLink to={props.secondary.link} target="_blank">
          <div className="contact-card-secondary-img-container">
            <img
              className="image"
              src={props.secondary.image}
              alt="contact-card-secondary-img"
            />
          </div>
        </NavLink>
      ) : (
        <div />
      )}
    </div>
  );
};

export default ContactCard;
