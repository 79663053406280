import "../styles/App.css";

import { Route, Routes } from "react-router-dom";
import React from "react";
import NavBar from "../components/NavBar";
import Home from "../sections/Home";
import Contact from "../sections/Contact";
import Projects from "../sections/Projects";
import About from "../sections/About";
import Footer from "../sections/Footer";

function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <div>
            <NavBar />
            <Home />
            <Projects />
            <About />
            <Contact />
            <Footer />
          </div>
        }
      />
    </Routes>
  );
}

export default App;
