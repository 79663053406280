import img1 from "../assets/technologies/java.png";
import img2 from "../assets/technologies/react.png";
import img3 from "../assets/technologies/flutter.png";
import img4 from "../assets/technologies/mongodb.png";
import img5 from "../assets/technologies/mysql.png";
import img6 from "../assets/technologies/python.png";
import img7 from "../assets/technologies/firebase.png";
import img8 from "../assets/technologies/nodejs.png";

const TechnologyCardData = [
  {
    image: img1,
  },
  {
    image: img2,
  },
  {
    image: img3,
  },
  {
    image: img4,
  },
  {
    image: img5,
  },
  {
    image: img6,
  },
  {
    image: img7,
  },
  {
    image: img8,
  },
];

export default TechnologyCardData;
