import "../styles/Home.css";

import Background from "../assets/backgrounds/image1.jpg";
import Logo from "../assets/icons/logo.svg";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { scrollToView } from "../util/Scroller.js";
import GradientLine from "../components/GradientLine.js";

const Home = () => {
  useEffect(() => {
    const handleParallax = () => {
      const background = document.querySelector(".home-background");
      const scrollPosition = window.scrollY;
      background.style.transform = `translateY(${scrollPosition * 0.2}px)`;
    };
    window.addEventListener("scroll", handleParallax);
    return () => {
      window.removeEventListener("scroll", handleParallax);
    };
  }, []);

  return (
    <div className="home" id="home">
      <div className="home-background">
        <img className="image" src={Background} alt="home-background" />
      </div>
      <div className="content">
        <h1>
          <img className="image" src={Logo} alt="logo-img" />
        </h1>
        <GradientLine />
        <p>We make Web & Mobile apps</p>
        <div className="home-container">
          <Link onClick={() => scrollToView("projects")} className="button">
            Projects
          </Link>
          <Link
            onClick={() => scrollToView("contact")}
            className="button-light"
          >
            Contact
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
