import "../styles/About.css";

import React from "react";

const WhyChooseUsCard = (props) => {
  return (
    <div className="why-choose-us-card">
      <div className="why-choose-us-card-img-container">
        <img className="image" src={props.image} alt="why-choose-us-card-img" />
      </div>
      <div className="why-choose-us-card-desc-container">
        <h2 className="why-choose-us-card-desc-title">{props.title}</h2>
        <h3 className="why-choose-us-card-desc-text">{props.text}</h3>
      </div>
    </div>
  );
};

export default WhyChooseUsCard;
