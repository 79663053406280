import "../styles/About.css";

import Background from "../assets/backgrounds/image3.jpg";
import React, { useEffect } from "react";
import WhyChooseUsCardData from "../data/WhyChooseUs";
import WhyChooseUsCard from "../components/WhyChooseUsCard";
import { Link } from "react-router-dom";
import { scrollToView } from "../util/Scroller.js";
import TechnologyCardData from "../data/Technology.js";
import TechnologyCard from "../components/TechnologyCard.js";
import GradientLine from "../components/GradientLine.js";

const About = () => {
  useEffect(() => {
    const handleParallax = () => {
      const background = document.querySelector(".about .section-background");
      const scrollPosition = window.scrollY;
      background.style.transform = `translateY(${scrollPosition * 0.2}px)`;
    };
    window.addEventListener("scroll", handleParallax);
    return () => {
      window.removeEventListener("scroll", handleParallax);
    };
  }, []);

  return (
    <div className="about" id="about">
      <div className="section-background">
        <img className="image" src={Background} alt="section-background" />
      </div>
      <div className="content">
        <h1>About</h1>
        <GradientLine />
        <p>Here's a glimpse about us</p>
        <div className="about-container">
          <div className="who-are-we">
            <h2 className="who-are-we-title">Who Are We?</h2>
            <h3 className="who-are-we-text">
              <br></br>
              We are a small group of people who love to create web and mobile
              applications for individuals and businesses around the globe.
              <br></br>
              <br></br>
              Let us know if you're interested, as we're always excited to learn
              about your ideas and work together to bring your vision to life.
            </h3>
            <br></br>
            <div className="who-are-we-button-container">
              <Link onClick={() => scrollToView("contact")} className="button">
                Contact
              </Link>
            </div>
          </div>
          <div className="why-choose-us">
            <h2 className="why-choose-us-title">Why Choose Us?</h2>
            <div className="why-choose-us-container">
              {WhyChooseUsCardData.map((val, ind) => {
                return (
                  <WhyChooseUsCard
                    key={ind}
                    image={val.image}
                    title={val.title}
                    text={val.text}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <p>Technologies we use</p>
        <div className="technology-card-container">
          {TechnologyCardData.map((val, ind) => {
            return <TechnologyCard key={ind} image={val.image} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default About;
