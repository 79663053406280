import "../styles/Contact.css";

import React from "react";
import ContactCardData from "../data/Contact";
import ContactCard from "../components/ContactCard";
import SocialCardData from "../data/Social";
import SocialCard from "../components/SocialCard";
import GradientLine from "../components/GradientLine";

const Contact = () => {
  return (
    <div className="contact" id="contact">
      <div className="content">
        <h1>Contact</h1>
        <GradientLine />
        <p>Reach out to us through</p>
        <div className="contact-card-container">
          {ContactCardData.map((val, ind) => {
            return (
              <ContactCard
                key={ind}
                image={val.image}
                title={val.title}
                text={val.text}
                link={val.link}
                secondary={val.secondary}
              />
            );
          })}
        </div>
        <br></br>
        <br></br>
        <p>Also, find us on</p>
        <div className="social-card-container">
          {SocialCardData.map((val, ind) => {
            return <SocialCard key={ind} image={val.image} link={val.link} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default Contact;
