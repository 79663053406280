import img1 from "../assets/projects/tictactoe1.png";
import img2 from "../assets/projects/cakeday1.png";
import img3 from "../assets/projects/deliveryapp1.jpg";

const ProjectCardData = [
  {
    image: img2,
    title: "Cakeday",
    text: "A simple and easy to use application that reminds you about important dates such as your close ones' birthdays, so that you don't miss out on any!",
    button: "View on Google Play",
    link: "https://play.google.com/store/apps/details?id=com.niranjan.android.cakeday",
  },
  {
    image: img1,
    title: "Tic Tac Toe",
    text: "A timeless classic paper and pencil game that players of all ages know and love. This app enables one to play with computer and other people, along with voice chat.",
    button: "View on Google Play",
    link: "https://play.google.com/store/apps/details?id=com.niranjan.android.tictactoe",
  },
  {
    image: img3,
    title: "Delivery Application",
    text: "A user friendly application that can be used by both customers and vendors to order and deliver water-cans.",
    button: "View on Google Play",
    link: "https://play.google.com/store/apps/details?id=com.niranjan.android.deliveryapp",
  },
];

export default ProjectCardData;
