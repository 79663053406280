import "../styles/Project.css";

import Background from "../assets/backgrounds/image2.jpg";
import React, { useEffect } from "react";
import ProjectCard from "../components/ProjectCard";
import ProjectData from "../data/Project";
import GradientLine from "../components/GradientLine";

const Projects = () => {
  useEffect(() => {
    const handleParallax = () => {
      const background = document.querySelector(
        ".projects .section-background"
      );
      const scrollPosition = window.scrollY;
      background.style.transform = `translateY(${scrollPosition * 0.2}px)`;
    };
    window.addEventListener("scroll", handleParallax);
    return () => {
      window.removeEventListener("scroll", handleParallax);
    };
  }, []);

  return (
    <div className="projects" id="projects">
      <div className="section-background">
        <img className="image" src={Background} alt="section-background" />
      </div>
      <div className="content">
        <h1>Projects</h1>
        <GradientLine />
        <p>Our works</p>
        <div className="project-card-container">
          {ProjectData.map((val, ind) => {
            return (
              <ProjectCard
                key={ind}
                image={val.image}
                title={val.title}
                text={val.text}
                button={val.button}
                link={val.link}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Projects;
