import "../styles/About.css";

import React from "react";

const TechnologyCard = (props) => {
  return (
    <div className="technology-card">
      <div className="technology-card-img-container">
        <img className="image" src={props.image} alt="technology-card-img" />
      </div>
    </div>
  );
};

export default TechnologyCard;
