import img1 from "../assets/icons/devices.svg";
import img2 from "../assets/icons/timer.svg";
import img3 from "../assets/icons/star.svg";

const WhyChooseUsCardData = [
  {
    image: img1,
    title: "User Friendly",
    text: "We build our applications with the best user interface, aiming to provide a seamless experience to the users.",
  },
  {
    image: img2,
    title: "On Time Delivery",
    text: "Besides meeting the client's requirements, we step up further by providing all our deliverables on-time.",
  },
  {
    image: img3,
    title: "Best Quality",
    text: "We focus on building high quality applications using well established technologies.",
  },
];

export default WhyChooseUsCardData;
