import img1 from "../assets/icons/github.svg";
import img2 from "../assets/icons/stackoverflow.svg";
import img3 from "../assets/icons/twitter.svg";
import img4 from "../assets/icons/linkedin.svg";
import img5 from "../assets/icons/instagram.svg";
import img6 from "../assets/icons/facebook.svg";

const SocialCardData = [
  {
    image: img3,
    link: "https://twitter.com/HustlersLogic",
  },
  {
    image: img4,
    link: "https://www.linkedin.com/company/HustlersLogic",
  },
  {
    image: img5,
    link: "https://www.instagram.com/HustlersLogicTech",
  },
  {
    image: img6,
    link: "https://www.facebook.com/HustlersLogic",
  },
  {
    image: img2,
    link: "https://stackoverflow.com/users/9178162/niranj1997",
  },
  {
    image: img1,
    link: "https://github.com/niranj1997",
  },
];

export default SocialCardData;
