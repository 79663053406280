import "../styles/Footer.css";

import React from "react";
import GradientLine from "../components/GradientLine";

const Footer = () => {
  return (
    <div className="footer" id="footer">
      <div className="content">
        <GradientLine />
        <p className="created">
          Created with ❤️ by Hustlers Logic Technologies
        </p>
        <p className="copyright">© Copyright 2023. All Rights Reserved</p>
      </div>
    </div>
  );
};

export default Footer;
